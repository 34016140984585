import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ErrorBoundary from "./component/ErrorBoundary";
import LabTest from "./container/labtest/LabTest";
import NotFound from "./container/NotFound/NotFound";

function Routes() {
  return (
    <ErrorBoundary>
      <Router>
        <Switch>
          <Route path="/my-lab" component={LabTest} />
          <Route path="*" component={NotFound} />
        </Switch>
      </Router>
    </ErrorBoundary>
  );
}

export default Routes;
