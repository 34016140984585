import React from "react";
import styles from "./NotFound.module.css";

function NotFound() {
  return (
    <div className={styles.root}>
      <span className={styles.info}>Seems your are lost!</span>
      <a
        href="https://okhati.com.np"
        rel="noopener noreferrer"
        className={styles.linkHome}
      >
        TAKE ME HOME
      </a>
    </div>
  );
}

export default NotFound;
