import React from "react";
import { isMobile } from "react-device-detect";
import { useLocation, Redirect } from "react-router-dom";
import styles from "./LabTest.module.css";

function MobileBrowser({
  dynamicLink,
  webLink,
}: {
  dynamicLink: string;
  webLink: string;
}) {
  return (
    <div>
      <div className={styles.mainText}>
        To open your report in Okhati Mobile App, click{" "}
        <span className={styles.boldText}>OPEN REPORT</span> below
      </div>
      <div>
        <a
          className={styles.btnAction}
          href={dynamicLink}
          rel="noopener noreferrer"
        >
          OPEN REPORT
        </a>
      </div>
      <div className={styles.note}>
        *Note: Currently, we support Android devices. Support for Ios devices is
        on it's way.
      </div>
      <hr className={styles.divider} />
      <div className={styles.linkSection}>
        <span className={styles.linkText}>
          Having trouble opening your report?
        </span>
        <a className={styles.link} href={webLink} rel="noopener noreferrer">
          CLICK HERE
        </a>
      </div>
    </div>
  );
}

function DesktopBrowser({ webLink }: { webLink: string }) {
  React.useEffect(() => {
    window.location.href = webLink;
  }, [webLink]);

  return <div className={styles.mainText}>Loading...</div>;
}

function LabTest() {
  const query = new URLSearchParams(useLocation().search);
  const webLink = query.get("wl");
  const dynamicLink = query.get("dl");

  if (!webLink || !dynamicLink) {
    return <Redirect to="/" />;
  }
  return (
    <div className={styles.root}>
      {isMobile ? (
        <MobileBrowser dynamicLink={dynamicLink} webLink={webLink} />
      ) : (
        <DesktopBrowser webLink={webLink} />
      )}
    </div>
  );
}

export default LabTest;
