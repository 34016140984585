import React, { Component } from "react";

interface Props {
  children: React.ReactNode;
}

interface State {
  hasError: Boolean;
  hasRetried: Boolean;
}

class ErrorBoundary extends Component<Props, State> {
  state = { hasError: false, hasRetried: false };

  static getDerivedStateFromError = () => ({ hasError: true });

  componentDidCatch = () => {
    if (this.state.hasRetried) {
      window.location.reload();
      return;
    }
    this.setState({ hasError: true });
  };

  resetState = () => {
    this.setState({ hasError: false, hasRetried: true });
  };
  render() {
    if (this.state.hasError) {
      return (
        <div
        // display="flex"
        // height="100vh"
        // flexDirection="column"
        // justifyContent="center"
        // alignItems="center"
        >
          <p>Oops! something went wrong.</p>
          <button color="primary" onClick={this.resetState}>
            Try again
          </button>
        </div>
      );
    }

    return this.props.children || null;
  }
}

export default ErrorBoundary;
